import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
<footer>
    <div className="maxWidth footercontent">
        <div className="footerGrid">
            <div className="fgridItem" >
                <div className="schemaFooter" itemScope itemType="http://schema.org/Person">
                    <h3 className="footerTitle FooterT1"  itemProp="name">Iliuta Stoica</h3>
                    <h4 className="headerSubText" itemProp="jobTitle">Frontend Developer</h4>
                    <div className="fAdress" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                        Address: <br/>
                        <span className="locality" itemProp="addressLocality"> Bucharest</span>,
                        <span className="country" itemProp="addressCountry"> Romania</span>
                    </div>
                </div>
                <a target="_blank" rel="nofollow noindex noopener noreferrer" href="https://www.facebook.com/iliuta.stoica" className="social-link" title="connect on">
                    <svg className="icon-fb" width="28" height="28"> <use  xlinkHref="#icon-fb"></use></svg>
                </a>
                <a target="_blank" rel="nofollow noindex noopener noreferrer" href="https://www.linkedin.com/in/iliutastoica" className="social-link" title="connect on linkedin">
                    <svg className="icon-linkedin" width="28" height="28"> <use  xlinkHref="#icon-linkedin"></use></svg>
                </a>
                <a target="_blank" rel="nofollow noindex noopener noreferrer" href="https://github.com/iliutastoica" className="social-link" title="connect on">
                    <svg className="icon-git" width="28" height="28"> <use  xlinkHref="#icon-git"></use></svg>
                </a>
                <a target="_blank" rel="nofollow noindex noopener noreferrer" href="https://codepen.io/iliutastoica/" className="social-link" title="connect on">
                    <svg className="icon-codepen" width="28" height="28"> <use  xlinkHref="#icon-codepen"></use></svg>
                </a>
            </div>
            <div className="fgridItem">
                <h3 className="footerTitle">Links</h3>
                <ul className="links">
                    <li className="footerItem">  <Link to="/about" > about </Link> </li>
                    <li className="footerItem">  <Link to="/projects" > projects </Link> </li>
                    <li className="footerItem">  <a href="mailto:hello@iliutastoica.ro"> Contact </a> </li>

                </ul>
            </div>
            <div className="fgridItem">
                <h3 className="footerTitle">Other Links</h3>
                <ul className="links">
                    <li className="footerItem">
                        <a href="https://www.behance.net/iliutastoica" target="_blank" rel="nofollow noopener noreferrer"> Behance </a> </li>
                    <li className="footerItem">
                        <a href="https://codepen.io/iliutastoica" target="_blank" rel="nofollow noopener noreferrer"> Codepen </a> </li>
                    <li className="footerItem">
                        <a href="https://twitter.com/iliutastoica" target="_blank" rel="nofollow noopener noreferrer"> Twitter </a> </li>
                    <li className="footerItem">
                        <a href="https://www.pinterest.com/iliutastoica/" target="_blank" rel="nofollow noopener noreferrer"> Pinterest </a> </li>
                </ul>
            </div>
        </div>
        <div className="copyright">Copyright &copy; {new Date().getFullYear()}. Built with{` `}<a href="https://www.gatsbyjs.org">Gatsby</a>. All rights reserved.</div>
	</div>
</footer>
)


Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer