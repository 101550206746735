import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
import { graphql } from "gatsby"

const Header = ({ siteTitle, data }) => (
  <header id="header" className="topHeader" role="banner">
    <nav className="topMenu">
      <Link to="/" className="logo" itemProp="url">
        ilistoica/dev
      </Link>
      <div className="navLinksWrapper">
        <ul id="menu-main-menu" className="menu">
          <li className="menu-item">
            {" "}
            <Link to="/"> home </Link>{" "}
          </li>
          <li className="menu-item">
            {" "}
            <Link to="/about"> about </Link>{" "}
          </li>
          <li className="menu-item">
            {" "}
            <Link to="/projects"> projects </Link>{" "}
          </li>
        </ul>
      </div>
      <span className="navLinksAfter"></span>
    </nav>
    <div className="headerBg">
      <div className="maxWidth">
        <div className="headerCopy">
          <span className="headerToptext">Hello, I am</span>
          <h1 className="HeaderTitle">{siteTitle}</h1>
          <h2 className="headerSubText">Frontend Developer</h2>
          <a
            href="mailto:hello@iliutastoica.ro"
            className="button whiteBTN"
            itemProp="email"
          >
            <span className="buttonText"> Contact me </span>
          </a>
        </div>
        <div className="headerImage">
          <Image />
        </div>
      </div>
    </div>
    <div className="maxWidth">
      <div className="socialLinks">
        <h4 className="followTitle"> Follow me on:</h4>
        <a
          target="_blank"
          rel="nofollow noindex noopener noreferrer"
          href="https://www.facebook.com/iliuta.stoica"
          className="social-link"
          title="connect on"
        >
          <svg className="icon-fb" width="28" height="28">
            {" "}
            <use xlinkHref="#icon-fb"></use>
          </svg>
        </a>
        <a
          target="_blank"
          rel="nofollow noindex noopener noreferrer"
          href="https://www.linkedin.com/in/iliutastoica"
          className="social-link"
          title="connect on linkedin"
        >
          <svg className="icon-linkedin" width="28" height="28">
            {" "}
            <use xlinkHref="#icon-linkedin"></use>
          </svg>
        </a>
        <a
          target="_blank"
          rel="nofollow noindex noopener noreferrer"
          href="https://github.com/iliutastoica"
          className="social-link"
          title="connect on"
        >
          <svg className="icon-git" width="28" height="28">
            {" "}
            <use xlinkHref="#icon-git"></use>
          </svg>
        </a>
        <a
          target="_blank"
          rel="nofollow noindex noopener noreferrer"
          href="https://codepen.io/iliutastoica/"
          className="social-link"
          title="connect on"
        >
          <svg className="icon-codepen" width="28" height="28">
            {" "}
            <use xlinkHref="#icon-codepen"></use>
          </svg>
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export const query = graphql`
  query {
    file(relativePath: { eq: "src/images/me.svg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Header
